<template>
  <Page full>
    <grid
      type="invoice"
      title="Invoices"
      :multiple="true"
      :isMain="true"
      :showDefaultFilterPresets="true"
      :filters="{
        invoice_status: '!x&&!i&&!d&&!h'
      }"
      :visible="['invoice_preview', 'nextSteps', 'quote_id', 'client_id', 'invoice_status']"
      :hasBanner="true"
    />
  </Page>
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    Grid
  }
}
</script>
